import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { OverlayContext } from '../context/OverlayContext';

export default function QuizSingleQuest({ singleData, selectedOptions, handleSkipped, nextState }) {
    const single = JSON.parse(singleData.options);
    // const [answers, setAnswers] = useState([]);
    const { data, setData, answers, setAnswers,attempts,setAttempt } = useContext(OverlayContext);
    console.log(singleData.options);
    console.log(single);

    useEffect(() => {
        const updatedData = attempts.map((item) => {
            const questId = singleData.id;
            if (item.id === questId) {
                return { ...item, isChecked: false };
            }
            return item;
        });
        setAttempt(updatedData);
    }, [nextState]);

    const handleClick = (e) => {
        console.log(e.target);
        const exist = answers.some((ans) => {
            return ans.id === singleData.id;
        })
        if (exist) {
            const updatedAnswer = answers.map((ans) => {
                if (ans.id === singleData.id) {
                    return { ...ans, ans: e.target.value, response: e.target.id }
                }
                return ans
            })
            setAnswers(updatedAnswer);
        } else {
            setAnswers([...answers, { id: singleData.id, ans: e.target.value, response: e.target.id }]);
        }
    }

    return (
        <>
            <div className=' w-full shadow-[0px_3px_12px] shadow-gray-300 rounded  p-4 mb-2'>
                <h1 className='mt-3 pl-4 mb-3 font-medium text-lg text-blue'>{singleData.id} : {singleData.question}</h1>
                <form>
                    <ul className='p-4' >
                        {single.map((option, index) => {
                            // console.log(index);
                            return (
                                <>
                                    <li key={index} className='pl-6 pb-4 '>
                                        <input
                                            type='radio'
                                            id={index}
                                            value={option}
                                            onChange={handleSkipped}
                                            onClick={handleClick}
                                            className='accent-pink mr-2'
                                            checked={selectedOptions === option}
                                            name='options'
                                        />
                                        <label htmlFor={index} >{option}</label>
                                    </li>
                                </>
                            )
                        })}
                    </ul>
                </form>
            </div>
        </>
    )
}
