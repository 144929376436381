import useLogout from '../hooks/useLogout';

export default function LogoutAnchor() {
    const handleLogout = useLogout();
    return (
        <div className="fixed bottom-[42%] -right-[2.2rem] z-20 bg-prussianBlue text-white font-black py-[0.2rem] 
            px-5 rounded-t-lg rotate-z-270 md:hidden" onClick={()=>handleLogout()}
        >
            Sign Out
        </div>
    )
}
