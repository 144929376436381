import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../img/logo/logo.png'

export default function QuizNav() {
    return (
        <header>
            <div className='py-2 '>
                <div className='container mx-auto items-center px-2'>
                    <div className='flex flex-wrap items-center py-3'>
                        <div className='max-sm:w-[92%] max-md:w-[94%]  max-md:mx-auto  max-md:shadow-[0px_3px_12px] max-md:shadow-gray-300 max-lg:w-1/3 lg:w-1/3 px-4'>
                            <Link to='/pages/dashboard' >
                                <img src={logo} alt='' className='max-[479px]:max-w-[220px] max-[479px]:block max-[479px]:mx-auto max-md:max-w-[280px]
                                    max-md:block max-md:mx-auto' />
                            </Link>
                        </div>
                        <div className='px-3 max-sm:w-[92%] max-md:py-4 max-md:mt-3 max-md:w-[94%] mx-auto max-md:shadow-[0px_3px_12px] max-md:shadow-gray-300 md:w-[66%] text-center  md:text-right'>
                            {/* <div className='flex'> */}
                            <div className='text-blue sm:text-lg font-semibold pr-4 inline-block '>
                                <span className='pr-3'>
                                    <i className="fa-solid fa-circle-question fa-lg text-pink" />
                                </span>
                                Total questions: <span className='text-red-600'>20</span>
                            </div>
                            <div className='text-pink text-center inline-block sm:text-lg font-semibold max-md:hidden'>
                                <span className='pr-3'>
                                    <i className="fa-solid fa-marker fa-lg"></i>
                                </span>
                                <Link>
                                    View Instructions
                                </Link>
                            </div>
                            {/* </div>   */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
