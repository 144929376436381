import React from 'react'
import Navbar from './Navbar'
import SlickSlider from './SlickSlider'
import Courses from './Courses'
import FundamentalCourses from './FundamentalCourses'
import Enroll from './Enroll'
import CounterArea from './CounterArea'
import Footer from './Footer'
import MainWrap from './MainWrap'
import ContactFormWrap from './ContactFormWrap'
import Overlay from './Overlay'
import ScrollWrap from './ScrollWrap'
import ContactAnchor from './ContactAnchor'
import LoginAncohor from './LoginAnchor'
import FundamentalCoursesData from '../data/FundamentalCoursesData'
import FundamentalCoursesData2 from '../data/FundamentalCoursesData2'
import FundamentalCoursesData3 from '../data/FundamentalCoursesData3'
import { EnrollData, EnrollData2, EnrollData3 } from '../data/EnrollData'
import { CounterData } from '../data/CounterData'
import { SlickSliderData } from '../data/SlickSliderData'

export default function SkillUp() {
    
    return (
        <>
            <ContactAnchor />
            <LoginAncohor />
            <ScrollWrap />
            <Navbar />
            <MainWrap />
            <ContactFormWrap />
            <SlickSlider data={SlickSliderData}/>
            <Courses />
            <FundamentalCourses  data={FundamentalCoursesData}/>
            <Enroll data={EnrollData}/>
            <FundamentalCourses  data={FundamentalCoursesData2}/>
            <CounterArea data={CounterData}/>
            <Enroll data={EnrollData2}/>
            <FundamentalCourses  data={FundamentalCoursesData3}/>
            <Enroll data={EnrollData3}/>
            <Footer />
            <Overlay />
        </>
    )
}
