import React, { memo, useContext, useEffect, useState } from 'react'
import { OverlayContext } from '../context/OverlayContext';

function QuizTimer() {
    const { start, finish, time, setFinish, data, handleSubmit, setData, timeInterval, setTimeInterval } = useContext(OverlayContext)
    const [timer, setTimer] = useState(time);

    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;

    useEffect(() => {
        if (start) {
            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 0) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            setTimeInterval(interval);

            return () => {
                clearInterval(interval);
                setTimeInterval(null);
            };
        } else {
            setTimer(time);
        }
    }, [start, time, setTimeInterval]);

    useEffect(() => {
        if (timer === 0) {
            clearInterval(timeInterval);
            setFinish(!finish);
            handleSubmit()
            setData(data);
        }
    }, [timer]);
    return (
        <div className='w-full shadow-[0px_3px_12px] shadow-gray-300 rounded text-center p-4 text-4xl 
            font-semibold mb-2'
        >
            {start ?
                <div>
                    <span className='text-blue'>{minutes < 10 ? '0' : ''}{minutes}:</span><span className='text-pink'>{seconds < 10 ? '0' : ''}{seconds}</span>
                </div>
                :
                <div>
                    <span className='text-blue'>00:</span><span className='text-pink'>00</span>
                </div>
            }
        </div>
    )
}

export default memo(QuizTimer)