import { Route, Routes } from 'react-router-dom'
import SkillUp from './components/SkillUp'
import { ModalProvider } from './context/ModalContext'
import { ContactFormProvider } from './context/ContactFormContext'
import { OverlayProvider } from './context/OverlayContext'
import Aos from 'aos';
import { useEffect } from 'react'
import './aos/dist/aos.css'
import FundamentalModules from './pages/FundamentalModules'
import BusinessModules from './pages/BusinessModules'
import WebDesignModule from './pages/WebDesignModule'
import PlacementModule from './pages/PlacementModule'
import InfrastructureModule from './pages/InfrastructureModule'
import TestingModule from './pages/TestingModule'
import DataScienceVisualizaton from './pages/DataScienceVisualizaton'
import CyberSecurityBitcoinModules from './pages/CyberSecurityBitcoinModules'
import TrendingModule from './pages/TrendingModule'
import useScrollToTop from './hooks/useScrollToTop'
import Form from './pages/Form'
import Dashboard from './pages/Dashboard'
import Api from './API/Api'
import Quiz from './pages/Quiz'

function App() {
  const { getToken } = Api();

  useScrollToTop();
  useEffect(() => {
    Aos.init({
      offset: 100,
      once: true,
    });
  }, []);

  if (!getToken()) {
    const navbarLogin =
      <div className='overflow-x-hidden'>

        <OverlayProvider>
          <ContactFormProvider>
            <ModalProvider>
              <Routes>
                <Route path='/' element={<SkillUp />} />
                <Route path='/pages/Fundamental Modules' element={<FundamentalModules />} />
                <Route path='/pages/Business Modules' element={<BusinessModules />} />
                <Route path='/pages/Web Designing Modules' element={<WebDesignModule />} />
                <Route path='/pages/Placement Modules' element={<PlacementModule />} />
                <Route path='/pages/Infrastructure Modules' element={<InfrastructureModule />} />
                <Route path='/pages/Testing Modules' element={<TestingModule />} />
                <Route path='/pages/Data Science & Visualization' element={<DataScienceVisualizaton />} />
                <Route path='/pages/Cyber Security & Bitcoin Modules' element={<CyberSecurityBitcoinModules />} />
                <Route path='/pages/Trending Modules' element={<TrendingModule />} />
                <Route path='/pages/form' element={<Form />} />
              </Routes>
            </ModalProvider>
          </ContactFormProvider>
        </OverlayProvider>
      </div>

    return navbarLogin;
  }
  return (
    <div className='overflow-x-hidden'>
      <OverlayProvider>
        <ContactFormProvider>
          <ModalProvider>
            <Routes>
              <Route path='/' element={<SkillUp />} />
              <Route path='/pages/Fundamental Modules' element={<FundamentalModules />} />
              <Route path='/pages/Business Modules' element={<BusinessModules />} />
              <Route path='/pages/Web Designing Modules' element={<WebDesignModule />} />
              <Route path='/pages/Placement Modules' element={<PlacementModule />} />
              <Route path='/pages/Infrastructure Modules' element={<InfrastructureModule />} />
              <Route path='/pages/Testing Modules' element={<TestingModule />} />
              <Route path='/pages/Data Science & Visualization' element={<DataScienceVisualizaton />} />
              <Route path='/pages/Cyber Security & Bitcoin Modules' element={<CyberSecurityBitcoinModules />} />
              <Route path='/pages/Trending Modules' element={<TrendingModule />} />
              <Route path='/pages/dashboard' element={<Dashboard />} />
              <Route path='/pages/quiz' element={<Quiz />} />
            </Routes>
          </ModalProvider>
        </ContactFormProvider>
      </OverlayProvider>
    </div>
  )
}

export default App
