import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Api() {
    const navigate = useNavigate();

    const getToken = () => {
        const tokenString =localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const userString = localStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }
    const saveToken = (user, token) => {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));

        setToken(token);
        setUser(user);
        navigate('/pages/dashboard');
    }
    const logout = () => {
        localStorage.clear();
        navigate('/');
    }
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const http = axios.create({
        baseURL: `https://skillupitacademy.com/backend/public/api`,
        headers: {
            "Content-Type": "application/json",
            Authorization:  `Bearer ${token}`,
        },
        withCredentials: true,
    });

    const transactionApi = axios.create({
        baseURL: `https://skillupitacademy.com/backend/public/api`,
    });
    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        http,
        logout,
        transactionApi 
    }
}
