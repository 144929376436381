import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button3 from './Button3';
import logo from '../img/PROFILE_SKILLUP_LOGO-02.jpg';
import { OverlayContext } from '../context/OverlayContext';
import { Link } from 'react-router-dom';
import { HiXMark } from "react-icons/hi2";

export default function Popup() {
    const { popupState, hidePopup } = useContext(OverlayContext);

    return (
        <CSSTransition
            in={popupState}
            timeout={500}
            classNames="modal"
            unmountOnExit
        >
            <div className='fixed inset-0 flex items-center justify-center z-10 '>
                <div className='h-auto rounded-xl w-5/6' data-aos-duration="500">
                    <div className='justify-center'>
                        <div className='border-0 shadow-2xl relative flex flex-col min-w-0 bg-white bg-clip-border rounded'>
                            <div className='p-0'>
                                <div className='mx-0'>
                                    <div className='p-6 w-200px'>
                                        <div>
                                            <button className='absolute right-4 top-2 font-bold' onClick={() => hidePopup()}>
                                                <HiXMark />
                                            </button>
                                        </div>
                                        <div className='max-w-16 mx-auto mb-2'>
                                            <Link to='/'>
                                                <img src={logo} alt="logo" />
                                            </Link>
                                        </div>
                                        <h5 className='font-normal text-sm leading-6 text-center text-[#666]'>
                                            Please contact nearest center
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}
