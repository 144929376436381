
function Time() {

    const newDate = new Date();
    // console.log(newDate);
    let date = newDate.getDate()
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if (month < 10) {
        month = "0" + month;
    }
    if (date < 10) {
        date = "0" + date;
    }

    const ddmmyyyy = `${date}/${month}/${year}`;
    // console.log(ddmmyyyy);

    let hour = newDate.getHours();

    if (hour < 10) {
        hour = "0" + hour;
    }
    // console.log(hour);

    let minute = newDate.getMinutes();

    if (minute < 10) {
        minute = "0" + minute;
    }
    // console.log(minute);

    let time = `${hour}:${minute}`

    if (hour < 12) {
        time = `${time}am`
    } else {
        time = `${time}pm`
    }

    const timeStamp = `${ddmmyyyy} ${time}`;

    return timeStamp
}

export default Time

// return 

