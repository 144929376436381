import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import Time from '../components/Time';
import { OverlayContext } from '../context/OverlayContext';
import useLogout from './useLogout';

const time = 5 * 60;
export const useIdleLogout = (idleTime = time) => {
    const idleRef = useRef(0);
    const handleLogout = useLogout();
    const { start } = useContext(OverlayContext);
    const [clickState, setClickState] = useState(false)
    // const { handleLogout } = useContext(OverlayContext);
    useEffect(() => {
        if (!start) {

            const timerIncrement = () => {
                idleRef.current += 1;
                if (idleRef.current > idleTime) {
                    handleLogout();
                    clearInterval(idleInterval);
                }
            };

            const idleInterval = setInterval(timerIncrement, 1000);

            const resetIdleRef = () => {
                idleRef.current = 0;
            };

            if (!localStorage.getItem('prevClickEvent')) {
                localStorage.setItem('prevClickEvent', '')
            }
            if (!localStorage.getItem('currClickEvent')) {
                localStorage.setItem('currClickEvent', '')
            }
            document.body.addEventListener('click', (e) => {
                console.log(e);
                Time();
                idleRef.current = 0;
                if (localStorage.getItem('currClickEvent') === '') {
                    localStorage.setItem('currClickEvent', Time())
                }
                else {
                    const currClickEvent = localStorage.getItem('currClickEvent')
                    localStorage.setItem('prevClickEvent', currClickEvent)
                    localStorage.setItem('currClickEvent', Time());
                }
                const prevClickEvent = localStorage.getItem('prevClickEvent')
                const currClickEvent = localStorage.getItem('currClickEvent')
                console.log(prevClickEvent);
                // const date1 = dayjs(prevClickEvent,"MM/DD/YYYY hh:mm A");
                // const date2 = dayjs(currClickEvent,"MM/DD/YYYY hh:mm A");
                
                // console.log(date1);
                // console.log(date2);
                const prevDate = prevClickEvent.split(' ');
                const currDate = currClickEvent.split(' ');
                console.log(prevDate);

                const date1 = new Date(prevDate);
                const date2 = new Date(prevDate);

            });

            document.body.addEventListener('mousemove', resetIdleRef);
            document.body.addEventListener('mousedown', resetIdleRef);
            document.body.addEventListener('keypress', resetIdleRef);
            document.body.addEventListener('scroll', resetIdleRef);

            return () => {
                document.body.removeEventListener('mousemove', resetIdleRef);
                document.body.removeEventListener('mousedown', resetIdleRef);
                document.body.removeEventListener('keypress', resetIdleRef);
                document.body.removeEventListener('click', resetIdleRef);
                document.body.removeEventListener('scroll', resetIdleRef);
                clearInterval(idleInterval);
            };
        }
    }, [handleLogout, idleTime, start]);
};