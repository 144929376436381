import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import logo from '../img/logo/logo.png'
import Button from './Button'
import { OverlayContext } from '../context/OverlayContext'

export default function HeaderBottom() {
    const { toggle } = useContext(OverlayContext)

    return (
        <div className={`container flex justify-between mx-auto items-center`}>
            <div className='py-[5px] px-[15px]'>
                <Link to='/'>
                    <img src={logo} alt='logo' className='max-sm:w-3/4 w-72' />
                </Link>
                <h5 className='mb-2 text-[18px]'>
                    Microsoft Offical Partner
                </h5>
            </div>
            <div className='flex'>
                <div className='hidden md:block mr-10'>
                    <Button text='login' />
                </div>
                <div className='w-20 mr-5'>
                    <span className='text-[30px] float-right cursor-pointer' onClick={() => toggle()}>☰</span>
                </div>
            </div>
        </div>
    )
}
